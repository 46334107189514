import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function ConcertHistory() {

  return (
    <div className='App'>
        concert history page (work in progress)
    </div>
  );
}

export default ConcertHistory;